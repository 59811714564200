import Meta from "@/src/component/meta/Meta";
import BlankLayout from "@/src/layouts/blank";
import Link from "next/link";
import { useEffect, useState } from "react";

export default function Custom404() {
  const [msg, setMsg] = useState(`This page doesn’t exist or was removed!
    we suggest you back to home`);

  useEffect(() => {
    window.Intercom("hide");
    window.Intercom("update", {
      hide_default_launcher: true,
    });
  }, []);

  return (
    <>
      <Meta title="404 - Not Found" description="Not found page" />
      <BlankLayout>
        <picture>
          <img
            src="/images/Illustration/404.svg"
            className="w-[12.5rem] h-[12.5rem]"
            alt="404"
          />
        </picture>
        <div className="mt-7 font-bold text-title1 text-neutral-900">404</div>
        <div className="mt-3 text-small-normal normal:text-regular-normal text-neutral-900 text-center w-full md:w-[36.125rem]">
          {msg}
        </div>
        <div className="mt-12 w-[13.75rem]">
          <Link href="/">
            <div className="w-full bg-neutral-900 text-white font-medium text-regular-none p-4 rounded-xl text-center">
              Back To Homepage
            </div>
          </Link>
        </div>
      </BlankLayout>
    </>
  );
}
