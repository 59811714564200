import Head from "next/head";
import { ReactNode, useEffect, useState } from "react";
import { Logo } from "../component/image/Images";
import { GeneralInformationProps } from "../utils/globalTypes";
import useGeneral from "../utils/useGeneral";

type Props = {
  children: ReactNode;
  className?: string;
  noLogo?: boolean;
  parentClassName?: string;
};

export default function BlankLayout({
  children,
  className,
  noLogo,
  parentClassName,
}: Props) {
  const [information, setInformation] =
    useState<GeneralInformationProps | null>(null);
  const { information: info } = useGeneral();

  useEffect(() => {
    const dataInfo = localStorage.getItem("general-information") || "";
    if (dataInfo) {
      const getData = JSON.parse(dataInfo);
      setInformation(getData);
    } else {
      setInformation(info);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="icon"
          href={`${information?.attributes?.icon_color.data?.attributes.url}`}
        />
        <link
          rel="shortcut icon"
          href={`${information?.attributes?.icon_color.data?.attributes.url}`}
        />
        <meta name="robots" content="index, follow" />
      </Head>
      <div
        className={`bg-white h-screen flex flex-col items-center p-3 md:p-12 gap-3 ${parentClassName}`}
      >
        <div>{!noLogo && <Logo />}</div>
        <div
          className={`h-full flex flex-col items-center normal:justify-center ${className}`}
        >
          {children}
        </div>
      </div>
    </>
  );
}
